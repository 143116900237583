<template>
  <div>
    <div class="top-bar2">
      <div class="account-title">Live Rate</div>
    </div>
    <div class="bottom block">
      <div class=" live-content block sm:flex items-center px-1vw sm:px-2">
        <div class="w-full sm:w-2/5 flex justify-between sm:items-center items-center">
          <label class="w-1/4 sm:w-1/5 sm:text-18px text-14px text-orange-main" for="Base Currency">
            Base Currency
          </label>
          <select
          v-model="baseCurrency"
          class="bg-inherit outline-none px-1 py-0 h-8  border-style2  w-1/4" name="Base Currency" id="">
            <optgroup label="Base Currency">
              <option>NZD</option>
              <option>AUD</option>
              <option>GBP</option>
              <option>EUR</option>
              <option>USD</option>
              <option>CAD</option>
              <option>CHF</option>
              <option>FJD</option>
              <option>MYR</option>
              <option>SGD</option>
              <option>CNY</option>
              <option>HKD</option>
              <option>JPY</option>
              </optgroup>
          </select>
          <input
          v-model='baseAmount'
          @input="handleBaseAmountChange"
          class=" w-1/4 bg-inherit h-8 outline-none border-style2" type="text">
        </div>
        <div class="w-full my-3 sm:my-0 sm:w-1/5 flex-shrink-0 text-center">
          <span class=" text-14px sm:text-20px text-blue-400">Rate @{{currentRate}}</span>
        </div>
        <div class="w-full sm:w-2/5 flex justify-between items-center">
        <label for="counter_currency" class=" text-14px sm:text-18px  w-1/4 text-orange-main">Transaction Currency</label>
        <select
        v-model="transactionCurrency"
        name="counter_currency" id="counter_currency" class="border-style2 h-8 bg-inherit  outline-none w-1/4">
        <optgroup label="Transaction Currency">
          <option value="NZD">NZD</option>
          <option value="AUD">AUD</option>
          <option value="GBP">GBP</option>
          <option value="EUR">EUR</option>
          <option value="USD">USD</option>
          <option value="CAD">CAD</option>
          <option value="CHF">CHF</option>
          <option value="FJD">FJD</option>
          <option value="MYR">MYR</option>
          <option value="SGD">SGD</option>
          <option value="CNY">CNY</option>
          <option value="HKD">HKD</option>
          <option value="JPY">JPY</option>
          </optgroup>
        </select>
        <input
        v-model='transactionAmount'
        @input="handleTransactionAmountChange"
        class="bg-inherit border-style2 outline-none h-8 w-1/4" type="text">
        </div>
      </div>
      <div class="relative flex items-center justify-center  py-2 mt-6 ">
        <label class="
        font-600 text-14px sm:text-18px text-black-45 mr-2 pl-0 sm:pl-10" for="currencyFilter">SearchBy(Symbol):</label>
        <div class=" relative overflow-hidden">
        <input
          placeholder="eg.NZD"
          v-model="searchValue"
          id="currencyFilter"
          class=" ml-4  w-60vw sm:w-216px bg-inherit  currencyFilter" type="text">
          <img class="searchIcon transition-all duration-200 ease-in  w-8 absolute" src="@/assets/icon/search-icon.svg" alt="">
        </div>
      </div>
      <table class="live-rate-table">
          <tr class=" bg-blue-600">
            <th>CurrencyPair <span class=" hidden sm:inline-block text-13px text-gray-500 font-400">(Refreshed at {{freshTime}})  </span></th>
            <th>Buy</th>
            <th>Sell</th>
          </tr>
          <tr class="table-row" v-for="item in filterList" :key="item.symbol">
            <th  class="w-1/2" >
              <img class="w-25px h-15px inline-block  sm:w-50px sm:h-30px" :src="item.baseCurrencyFlag" alt="">
              <span class=" mx-1 sm:mx-2">{{item.symbol}}</span>
              <img class="w-25px h-15px inline-block sm:w-50px sm:h-30px " :src="item.transactionCurrencyFlag" alt="">
              </th>
            <th class=" table-th"><span>{{item.bid}}</span> <a
            @click="hanldeEngage('bid',item.symbol)"
            class=" cursor-pointer engageBtn float-right hidden sm:inline-block">engage</a></th>
            <th class=" table-th"><span>{{item.ask}}</span><a
            @click="hanldeEngage('ask',item.symbol)"
            class=" hidden sm:inline-block cursor-pointer engageBtn  float-right">engage</a> </th>
          </tr>
      </table>
      <!-- <div class="live-rate-container">
        <div class="row flex justify-between bg-blue-main rounded-sm py-2">
          <div  class=" px-2 py-1 text-20px font-700 text-white">CurrencyPair</div>
          <div class=" flex">
            <div class=" text-20px text-white px-6 py-1">Buy</div>
            <div class=" text-20px text-white px-6 py-1">Sell</div>
          </div>
        </div>
        <div
        v-for="item in currencyArray"
         class="row flex justify-between rate-row"
        :key='item.symbol'>
          <div  class=" px-2 py-1 text-20px font-500 text-orange-main flex ">
            <img :src="item.baseCurrencyFlag" alt="">
            <p class=" mx-2">{{item.symbol}}</p>
            <img :src="item.transactionCurrencyFlag" alt="">
            </div>
          <div class=" flex">
            <div class=" text-20px text-orange-main  px-6 py-1">{{item.bid}}</div>
            <div class=" text-20px text-orange-main px-6 py-1">{{item.ask}}</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { getRealtimeRateTable } from '@/api/root'
import { getExchangeRate } from '@/api/common'
import getLocalTime from '@/utils/getLocalTime'

export default {
  data () {
    return {
      baseAmount: 1000,
      baseCurrency: 'NZD',
      transactionAmount: 0,
      transactionCurrency: 'CNY',
      searchValue: '',
      currentRate: 1,
      currencyArray: [],
      testArray: [],
      freshTime: '',
      params: {
        from: 'NZD',
        to: 'CNY'
      },
      operator: ''
    }
  },
  created () {
    this.freshTime = getLocalTime()
    getRealtimeRateTable().then((res) => {
      res.forEach((ele, i, arr) => (
        arr[i].symbol = ele.symbol.replace('|', ' / ')
      ))
      this.currencyArray = res
    }).catch(
    )
    getExchangeRate(this.params).then(res => {
      this.currentRate = res?.exchangeRate
      this.operator = res?.operator
      this.reset()
    })
  },
  watch: {
    baseCurrency (newValue, oldValue) {
      if (newValue === this.transactionCurrency) {
        this.currentRate = 1
      } else {
        this.params.from = newValue
        if (this.params.from !== this.params.to) {
          this.getRate().then(res => {
            this.currentRate = res?.exchangeRate
            this.operator = res?.operator
          })
        }
      }
    },
    transactionCurrency (newValue, oldValue) {
      if (newValue === this.baseCurrency) {
        this.currentRate = 1
      } else {
        this.params.to = newValue
        if (this.params.from !== this.params.to) {
          this.getRate().then(res => {
            this.currentRate = res?.exchangeRate
            this.operator = res?.operator
            this.reset()
          })
        }
      }
    }
  },
  methods: {
    handleTransactionAmountChange () {
      this.baseAmount = this.transactionAmount / this.currentRate
    },
    handleBaseAmountChange () {
      this.transactionAmount = this.baseAmount * this.currentRate
      // this.baseAmount
    },
    ...mapActions(['GetRealEXRatesAction']),
    hanldeEngage (type, symbol) {
      symbol = symbol.replace(/\s+/g, '').split('/')
      if (type === 'bid') {
        if (symbol[0] !== this.baseCurrency) {
          this.baseCurrency = symbol[0]
        }
        if (symbol[1] !== this.transactionCurrency) {
          this.transactionCurrency = symbol[1]
        }
      } else {
        if (symbol[1] !== this.baseCurrency) {
          this.baseCurrency = symbol[1]
        }
        if (symbol[0] !== this.transactionCurrency) {
          this.transactionCurrency = symbol[0]
        }
      }
    },
    reset () {
      this.baseAmount = 1000
      this.transactionAmount = this.baseAmount * this.currentRate
    },
    getRate () {
      return getExchangeRate(this.params)
    },
    valueChange () {
      console.log('change')
    }
  },
  computed: {
    getTransactionAmount: {
      get () {
        if (this.operator === '*') {
          return (this.baseAmount * this.currentRate)
        } else {
          return this.baseAmount / this.currentRate
        }
      },
      set (val) {
        this.baseAmount = val / this.currentRate
      }
    },
    getRealRate () {
      if (this.baseCurrency === this.transactionCurrency) {
        return 1.00
      } else {
        return this.currencyArray.find(ele =>
          ele.symbol === (this.baseCurrency + '/' + this.transactionCurrency)
        )?.bid
      }
      // return this.baseCurrency + '/' + this.transactionCurrency
    },
    filterList () {
      if (this.searchValue === '') {
        return this.currencyArray
      } else {
        return this.currencyArray.filter(item => item.symbol.includes(this.searchValue.toUpperCase()))
      }
    }
  }
}
</script>

<style scoped>
.live-content{
  min-width: 780px;
}
.live-rate-container{
  width: 540px;
}
.currencyFilter{
  border-bottom: 1px solid #555555;
  outline: none;
}
.searchIcon{
  top: calc(50% - 16px);
  right: -5px;
}

.currencyFilter:focus + .searchIcon{
  right: -30px;
}
.rate-row{
  border-bottom: 1px solid #163257;
}
.live-rate-table {
  font-family: PingFangSC, PingFang SC,"Microsoft Yahei", SimHei, SimSong, Arial;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}
.live-rate-table td, .live-rate-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

/* .live-rate-table tr:nth-child(even){
  background-color: #f2f2f2;
  }
.live-rate-table tr:nth-child(1){
  background-color: #163257;
  } */
.table-row:hover > .table-th > a{
  color: rgb(98, 147, 211);
  text-decoration: underline;
  opacity: 1;
}

.live-rate-table th {
  padding-top: 12px;
  padding-left: 15px;
  padding-bottom: 12px;
  text-align: left;
  color: #BC9A41;
}
.live-rate-table th:first-child {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 30px;
  text-align: left;
  color: #BC9A41;
}
.border-style{
  border-radius: 6px;
}
.border-style2{
  border-bottom: 1px solid #555555;
}
.engageBtn{
 padding: 4px 6px;
 opacity: 0;
 color: #163257;
}
@media  only screen and  (max-width: 600px) {
  .live-content{
    min-width: 0;
  }
  .live-rate-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  color: #BC9A41;
}
.live-rate-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  text-align: center;
  align-content: center;
  color: #BC9A41;
}
.live-rate-table th:first-child {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
  text-align: left;
  color: #BC9A41;
}
}
</style>
